import type { ListVariantTypeAndProps } from '@aurora/shared-client/components/common/List';
import type { PagerVariantTypeAndProps } from '@aurora/shared-client/components/common/Pager/types';
import type { ContextNodeFragment } from '@aurora/shared-generated/types/graphql-types';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { FieldValues } from 'react-hook-form';
import type {
  CoreNode,
  Message,
  MessageSorts
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { ClampLinesType } from '@aurora/shared-types/community/enums';
import type { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import type { ItemType, MessageViewVariant } from '../../types/enums';
import type {
  QuickMessageSearchQuery,
  QuickMessageSearchViewFragment,
  MessageActionMenuFragment,
  UserViewFragment
} from '../../types/graphql-types';
import type { ItemListCommonProps } from '../common/List/ItemList';
import type { ComponentCommonProps, WidgetProps } from '../common/Widget/types';
import type { ItemViewVariantProps, ItemViewTypeAndProps } from '../entities/types';
import type { SearchItemType } from '../search/enums';
import type { MessageViewCardProps, MessageViewInlineProps } from './MessageView/types';
import type { NodePickerNode } from '@aurora/shared-client/components/nodepicker/types';

export type MessageList = ItemListCommonProps<
  Message,
  ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>
>;

/**
 * Contributors list type
 */
export enum ContributorsListType {
  NONE = 'none',
  LINK = 'link',
  PANEL = 'panel'
}

export interface MessageListWidget extends ComponentCommonProps, WidgetProps, MessageList {
  /**
   * The title
   */
  title?: string;
  /**
   * Whether to show title or not
   */
  useTitle?: boolean;

  /**
   * Type of panel to use for widget
   */
  panelType?: PanelType;
}

export interface MessageWithRepliesWidgetProps extends MessageListWidget {
  /**
   * If true, only fetch reply data during the client-side render, not during the server-side render.  Default is false.
   */
  lazyFetchReplies?: boolean;
}

export interface TkbMessageWithRepliesWidgetProps extends MessageWithRepliesWidgetProps {
  /**
   * If true, display the guide navigation section. Default is false.
   */
  showGuideNavigationSection?: boolean;
  /**
   * Show published or last updated date
   */
  showTimestamp?: boolean;
  /**
   * Show version number
   */
  showVersion?: boolean;
  /**
   * Show helpfulness
   */
  showHelpfulness?: boolean;
  /**
   * Contributor list type
   */
  contributorListType?: ContributorsListType;
}

/**
 * Message action
 */
export interface MessageActionType {
  /**
   * The message to take action on
   */
  message: MessageActionMenuFragment;
}

/**
 * Message sort option.
 */
export interface MessageSortByOption {
  /**
   * key of the field.
   */
  key: string;
  /**
   * Possible value of the field.
   */
  value: MessageSorts;
}

/**
 * Configuration form data for the widget configuration
 */
export interface MessageListWidgetConfigurationFormData extends FieldValues {
  style: string;
  pageSize: number;
  panelType: PanelType;
  sorts: MessageSorts;
  viewPropsInline: MessageViewInlineProps;
  viewPropsCompact: MessageViewInlineProps;
  viewPropsCard: MessageViewCardProps;
  previewLengthInline: ClampLinesType;
  previewLengthCard: ClampLinesType;
  leadIcon: string;
  showTabsFilter: {
    showTabs: boolean;
  };
  filterOptions: {
    addTags: boolean;
  };
  defaultTabs: MessageTabItem;
  additionalTabs: MessageTabItem;
  title: string;
  moreOptions: {
    hideIfEmpty: boolean;
    pagerOption: boolean;
    pagerOptionCard: boolean;
    lazyLoad: boolean;
  };
  /**
   * Node to scope this component to
   */
  nodeScope: NodePickerNode;
}

/**
 * Extended message list widget props(existing) to support few other fields while configuring Message List widgets.
 */
export interface MessageListWidgetExtendedProps extends MessageListWidget {
  /**
   * Specifies the sort order for the list.
   */
  sorts?: MessageSorts;
  /**
   * Specifies the message list style.
   */
  style?: string;
  /**
   * Specifies the messages sort by field options.
   */
  sortByFieldValues?: MessageSortByOption[];
  /**
   * Specifies the conversation style for the widget.
   */
  conversationStyle?: string;
  /**
   * Specifies the message tabs to be shown and it's states.
   */
  tabItemMap?: MessageTabOptions;
  /**
   * Specifies whether to show tabs or not.
   */
  showTabs?: boolean;
  /**
   * Specifies whether to add tags filter or not.
   */
  addTags?: boolean;
  /**
   * Specifies title's context variant if present.
   */
  titleContextVariant?: string;

  /**
   * Whether to show footer or not
   */
  useFooter?: boolean;

  /**
   * The node scope
   */
  nodeScopeId?: CoreNode['id'];

  /**
   * Whether to include pagination.
   */
  pagerOption?: boolean;

  /**
   * Whether to include pagination in card layout.
   */
  pagerOptionCard?: boolean;
}

/**
 * Message Tab Item
 */
export interface MessageTabItem {
  /**
   * Specifies the visibility property of message tabs.
   */
  [MessageListTabItem: string]: boolean;
}

/**
 * Message Tab Options
 */
export interface MessageTabOptions {
  /**
   * Specifies the default tabs for messages.
   */
  default: MessageTabItem;
  /**
   * Specifies the additional tabs for messages.
   */
  additional: MessageTabItem;
}

/**
 * Date with timezone
 */
export interface DateTimeZone {
  /**
   * Date with time
   */
  dateTime: Date;
  /**
   * Timezone
   */
  timezone?: string;
}

/**
 * Extended user view fragment to support user type that includes author, coAuthor and contributor.
 */
export interface Contributor extends UserViewFragment {
  /**
   * Species the type of user and accepts `UserType`enum type.
   */
  userType: UserType;
}

export enum WidgetType {
  TOP = 'top',
  RECENT = 'recent'
}

export enum UserType {
  AUTHOR = 'author',
  COAUTHOR = 'coAuthor',
  CONTRIBUTOR = 'contributor'
}

export interface MessageListForQuickSearchProps {
  /**
   * Callback that triggers when useQuery is success.
   *
   * @callback
   * @param results from useQuery graphQl call
   */
  onCompleted?: (searchResults: QuickMessageSearchQuery) => void;
  /**
   * Property to pass the term to query.
   */
  queryTerm?: string;
  /**
   * Node which search should be scoped to. When not provided, search is scoped to community.
   */
  contextNode?: ContextNodeFragment;
  /**
   * Whether to use header for the PaneledItemList.
   */
  useHeader?: boolean;
  /**
   * The list variant type and props.
   */
  listVariant: ListVariantTypeAndProps<QuickMessageSearchViewFragment>;
  /**
   * Message view variant props overrides.
   */
  messageViewVariantPropsOverrides?: ItemViewVariantProps<
    ItemType.MESSAGE_SEARCH,
    MessageViewVariant.INLINE
  >;
  /**
   * Pager variant type and props.
   */
  pagerVariant?: PagerVariantTypeAndProps<EndUserPages, EndUserQueryParams>;
  /**
   * Classname for the panel header section.
   */
  headerClassname?: string;
  /**
   * Whether AI search is enabled or not.
   */
  aiSearchEnabled?: boolean;
  /**
   * Whether to display empty state or not.
   */
  useEmpty?: boolean;
  /**
   * Classname for the panel list section.
   */
  paneledListClassname?: string;
  /**
   * Callback triggered when component render is done.
   *
   * @callback
   * @param pageNumber page number of the results.
   * @param type mention item type.
   */
  onRendered?: (pageNumber: number, type: SearchItemType) => void;
  /**
   * Constrain number of pages.
   */
  constrainPages?: number;
  /**
   * Message to show in header
   */
  headerMessage?: string;
  /**
   * Search for terms greater than theshold value.
   */
  queryTermThreshold?: number;
}
